import React, { createElement as h } from "react"
import Layout from "../layout"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
import { Page } from "../post"
import Footer from "../footer"

export default ({ data }) => {
  const post = data.page
  // .responsive class required for Wordpress theme styles.
  return (
    <Layout>
      <motion.main animate="undefined" exit="undefined" className="responsive">
        <Helmet></Helmet>
        <Page title={post.title} content={post.content} />
        <Footer />
      </motion.main>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`
